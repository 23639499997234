import Vue from "vue";
import langFromVue from "./Language.vue";
import store from '@/store';

const LangFormConstructor = Vue.extend(langFromVue);

let instance;

const initInstance = () => {
  instance = new LangFormConstructor({
    el: document.createElement("div"), 
    store
  });
  document.body.appendChild(instance.$el);
};

const showLanguageForm = (/* options */) => {
  if (!instance) {
    initInstance();
  }

  document.body.appendChild(instance.$el);
  const wrapper = document.querySelector('.app-wrapper');
  if (wrapper) wrapper.classList.add('has-popup');

  Vue.nextTick(() => {
    instance.active = true;
    Vue.nextTick(() => {
      instance.initShow();
      setTimeout( () => { instance.visible = true; }, 50);
    });
  });
};

const LanguageForm = function(options) {
  if (Vue.prototype.$isServer) return;
  
  showLanguageForm(options);
};

LanguageForm.show = options => {
  return LanguageForm(options);
};

LanguageForm.close = () => {
    instance.visible = false;
      setTimeout(() => {
        instance.active = false;
      }, 300);
  const wrapper = document.querySelector('.app-wrapper');
  if (wrapper) wrapper.classList.remove('has-popup');
};

export default LanguageForm;
export { LanguageForm };