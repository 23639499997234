import { ConfiguratorFormDataService } from "@/dataservice";
import { LoginController, ContentController } from "@/controller";
import Store from "@/store";
import { EventBus } from "@/bus/eventbus.js";
import PostData from "@/utils/PostData.js";
import Uniqe from "@/utils/Uniqe.js";
import Cookie from "@/utils/Cookie";

const ConfiguratorFormController = {
    formFields: [],
    allConfigurationDone: false,
    formMode: "basic",
    formModeCookieName: "_idm_configurator_form_mode",
    localStorageFormDataKey: "_idm_configurator_form_data",
    sessionStoredDataAvailable: false,
    localStorageFormExpertModeDataKey: "_idm_configurator_form_expert_mode",
    startedCookieName: "_idm_configurator_started_on_mobile",
    undoValues: [],
    additionalValuesEntered: false,

    //formModeSwitch Dummy to be added before the first expert field or at the end of the fields
    formModeSwitchField: {
        "expert": false,
        "label": "Weitere Parameter anzeigen",
        "name": "CONF_VAR_FORMMODE_SWITCH",
        "uid": Uniqe.getUniqe(),
        "type": "boolswitch",
        "default": 0,
        "show": true
    },

    checkConfigurationStartedOnMobile() {
        let started = Cookie.readCookie(this.startedCookieName);
        Store.dispatch("setStartedOnMobile", started);
    },

    configurationStartedOnMobile() {
        Cookie.createCookie(this.startedCookieName, true);
        Store.dispatch("setStartedOnMobile", true);
    },

    getFormValues() {
        return ConfiguratorFormDataService.values;
    },

    setFormValues(values) {
        ConfiguratorFormDataService.values = values
    },

    getFormFieldCount() {  
        return Object.keys(this.formFields).length;
    },

    setFormMode(formMode) {
        console.log("ConfiguratorFormController:setFormMode:formMode", formMode);
        this.formMode = formMode;
        Cookie.createCookie(this.formModeCookieName, formMode);
        Store.dispatch('setConfiguratorFormMode', formMode);
    },

    setStepExpertMode(step, mode) {
        if (this.formFields && this.formFields[step]) this.formFields[step].expertMode = mode;
        this.storeFormExpertModesToLocalStorage();
        if (!mode) this.resetOnExpertModeChangeFieldValues(step);
        //Cookie.createCookie(this.formModeCookieName, formMode);
        //Store.dispatch('setConfiguratorFormMode', formMode);
        this.additionalValuesEntered = this.formFields.filter(st => st.expertMode).length > 0;
        //console.log("ConfiguratorFormController:setStepExpertMode:additionalValuesEntered", this.additionalValuesEntered);
        EventBus.$emit("onConfiguratorFormStepExpertModeChanged");
    },

    getFormMode() {
        let mode = Cookie.readCookie(this.formModeCookieName);
        if (!mode) mode = Store.state.app.configuratorFormMode || "basic";
        this.setFormMode(mode);
        console.log('ConfiguratorFormController->getFormMode()', mode, this.formMode, Store.state.app.configuratorFormMode);
        return this.formMode;
    },

    getStepExpertMode(step) {
        const data = this.getFormExpertModesFromLocalStorage();
        let mode;
        //let mode = Cookie.readCookie(this.formModeCookieName);
        if (!data) {
            mode = (this.formFields && this.formFields[step] && this.formFields[step].expertMode) || false;
            this.setStepExpertMode(step, mode);
        } else {
            mode = data[step];
        }
        console.log('ConfiguratorFormController->getStepExpertMode()', mode);
        return mode;
    },

    resetAndClearUndoValues() {
        ConfiguratorFormDataService.values = JSON.parse(JSON.stringify(this.undoValues));
        this.undoValues = [];
        console.log("ConfiguratorFormController:clearUndoValues:after", ConfiguratorFormDataService.values);
    },

    updateStep() {
        EventBus.$emit("onBeforeUpdateResult");
        if (Store.state.app.displayResult && this.undoValues.length) {
                //undo
            console.log('ConfiguratorFormController:delete undo values', this.undoValues);
                //delete undo values
            this.undoValues = [];
        } 
        this.postData();
    },

    nextStep() {
        console.log('ConfigurationFormController:nextStep:step', Store.state.app.activeFormStep);
        if (Store.state.app.activeFormStep < this.getFormFieldCount()-1) {
            Store.dispatch("setActiveFormStep", ++Store.state.app.activeFormStep);
            if (Store.state.app.activeFormStep > Store.state.app.formStepsDone) Store.dispatch("setFormStepsDone", ++Store.state.app.formStepsDone);
                //this.generateFormFields();
            this.updateFormFields();
        } else {
            this.postData();
            this.allConfigurationDone = true;
        }                 

    },

    prevStep() {
        if (Store.state.app.activeFormStep > 0) {
  
            this.allConfigurationDone = false;
            Store.dispatch("setActiveFormStep", --Store.state.app.activeFormStep);
                //this.generateFormFields();
            this.updateFormFields();
            console.log('ConfigurationFormController.prevStep values', this.getFormValues());
        }
    }, 

    setStep(step, displayForm, noScrollTop) {
        console.log('ConfigurationFormController:setStep:step', Store.state.app.activeFormStep, step, this.undoValues.length);
        if (step >= 0 && step < this.getFormFieldCount()) {
            Store.dispatch("setActiveFormStep", step);
            //this.generateFormFields();
            this.updateFormFields();
            //console.log('ConfiguratorFormController[setStep]', step, Store.state.app.displayResult)
            if (displayForm && Store.state.app.displayResult) {
                if (!ConfiguratorFormController.undoValues.length) {
                    this.undoValues = JSON.parse(JSON.stringify(ConfiguratorFormDataService.values));
                    console.log('ConfiguratorFormController:setStep:setUndoValues', step, ConfiguratorFormController.undoValues);
                }
                console.log('ConfiguratorFormController:setStep', step);
                Store.dispatch("setEditFormOnResult", true);
            }
            EventBus.$emit("onConfigurationFormSetStep", noScrollTop);
        }
    },

    checkAllSteps() {
        let completed = true;
        const currentStep = Store.state.app.activeFormStep;
        while (!this.allConfigurationDone && completed) {
            completed = ConfiguratorFormController.checkStepCompleted(Store.state.app.activeFormStep)
            console.log("ConfigurationController:checkAllSteps:step,completed", Store.state.app.activeFormStep, completed);
            if (completed) this.nextStep();
        }
        if (!completed) {
            Store.state.app.activeFormStep = currentStep;        
        }
    },

    reset() {
        Store.dispatch("setActiveFormStep", 0);
    },

    resetValues() {
        ConfiguratorFormDataService.resetValues();
    },

    updateResultOnModeChange() {
        EventBus.$emit("onBeforeUpdateResult");
            //only post the data if all configuration done.
            //on login / logout the data is stored, but must not be correct
        ConfiguratorFormController.checkAllSteps();
        if (ConfiguratorFormController.allConfigurationDone) this.postData();
    },

    async getData() {
        await this.fetchData();
        console.log('ConfiguratorFormController.getData', this.formFields);
        return this.formFields;
    },

    async postData() {
        //console.log('ConfiguratorFormDataService.values', ConfiguratorFormDataService.values);
        //console.log('LoginController.token: ', LoginController.token);
        this.storeFormDataToLocalStorage(ConfiguratorFormDataService.values);
        const postData = this.getFormPostData(ConfiguratorFormDataService.values);
        console.log('ConfiguratorFormController->postData formPostData', postData);
        const response = await ConfiguratorFormDataService.postFormData(postData, this.additionalValuesEntered, LoginController.token);
        EventBus.$emit("onConfigurationFormResponseReceived", response);
        console.log("ConfiguratorFormController:postData:response", response);
        console.log('================================== POSTDATA DEBUG BEGIN');
        console.log(response.data.debug);
        console.log('================================== POSTDATA DEBUG END');
    },

    async getFormPart(step, modeChanged) {
        //console.log('ConfiguratorFormController.getFormPart', step);
        if (modeChanged || !ConfiguratorFormDataService.data.length) {
            await this.fetchData();
        }
        return (step<this.formFields.length?this.formFields[step]:[]);
    },

    async fetchData() {
        const data = await ConfiguratorFormDataService.fetchFormData(Store.state.app.mode, Store.state.app.configuratorFormMode, LoginController.token);
        this.addRuntimeValues();
        this.generateFormFields();
        console.log('ConfiguratorFormController.fetchData -> data', data);
        if (ConfiguratorFormDataService.sessionStoredData) {
            this.sessionStoredDataAvailable = true;
            console.log('ConfiguratorFormController.fetchData -> got sessionStoredData', ConfiguratorFormDataService.sessionStoredData);
        }
        return data;
    },

    async weatherPrefetch() {
        const postData = this.getWeatherFormPostData(ConfiguratorFormDataService.values);
        //console.log('weatherPrefetch', postData);
        await ConfiguratorFormDataService.weatherPrefetch(postData);
        //console.log('weatherPrefetch', response);
    },

    addRuntimeValues() {
        const storedStepExpertData = this.getFormExpertModesFromLocalStorage();
        ConfiguratorFormDataService.data.forEach((step, stepIdx) => {
            step.expertMode = (storedStepExpertData ? storedStepExpertData[stepIdx] : false );
            step.fields.forEach((field, idx) => {
                field.hasError = false;
                field.index = idx;
                field.show = false;
                if (field.type == "hidden") field.vueType = "InputHidden"
                else if (field.type == "boolswitch") field.vueType = "BoolSwitch"
                else if (field.type == "radio_horizontal" || field.type == "radio_vertical") field.vueType = "RadioGroup"
                else if (field.type == "select_multiple_vertical") field.vueType = "CheckGroup"
                else if (field.type == "select_multiple_horizontal") field.vueType = "CheckButtonGroup"
                else if (field.type == "number") field.vueType = "InputField"
                else if (field.type == "bool") field.vueType = "BoolButton"
                else if (field.type == "boolswitch") field.vueType = "BoolSwitch";
                else if (field.type == "heading") field.vueType = "Heading"
                else if (field.type == "select") field.vueType = "SelectField"
                else field.vueType = "Unkown"
                if (field.options) {
                    field.options.forEach(o => {
                        o.show = true;
                        o.uid = Uniqe.getUniqe();
                    });
                }
                if (field.conditions) {
                    field.conditions.forEach(con => {
                        if (con.options) {
                            con.options.forEach(o =>{
                                o.show = true;
                                o.uid = Uniqe.getUniqe();
                            });
                        }
                    });
                }
            });
        });
    },

    generateFormFields() {
        //console.log('generateFormFields', this.formMode);
        this.formFields = [];
        ConfiguratorFormDataService.data.forEach(step => {
            const entry = {
                'title': step.title,
                'fields': [],
                'expertMode': step.expertMode
            };
            //let idx = 1;
            let expertSwitchAdded = false;
            step.fields.forEach(field => {
                field.uid = Uniqe.getUniqe();
                let options = [];
                let showHide = true;
                let setOptions = false;
                    //console.log('check options / conditions', field.name, field.options, field.conditions);
                    //if (field.name == "CONF_DHW_HEADING") console.log('field.conditions', field.name, field.options, field.conditions);
                    //console.log('generateFormFields', field.expert, this.formMode, field.expert && this.formMode == 'basic');
                if (/* LoginController.loggedIn && */ field.expert && !expertSwitchAdded) {
                    expertSwitchAdded = true;
                    this.formModeSwitchField.default = /* ConfiguratorFormController.formMode  == "expert" */ step.expertMode ? 1 : 0;
                    if (!LoginController.loggedIn) this.formModeSwitchField.show = false;
                    entry.fields.push(ConfiguratorFormController.formModeSwitchField);                    
                }
                if (!LoginController.loggedIn && field.expert) {
                    showHide = false;
                }
                if (LoginController.loggedIn && field.expert && !step.expertMode /* this.formMode == 'basic' */) {
                    //console.log('generateFormFields', this.formMode, field.expert);
                    showHide = false;
                } /* else {*/ //generateFields must build all options

                    if (field.options) {
                        options = field.options;
                    }
                    if (field.conditions) {
                        field.conditions.forEach(cond => {
                            if (cond.then == "set_options") {
                                    //the check must be d
                                /* let addOptions = */ this.checkShowOption(cond);
                                //if (field.name == "CONF_VAR_SELECTION_ADDITIONAL") console.log('options', field.name, options, cond);
                                //if (addOptions && addOptions.length) {
                                    //console.log('found opts and add to ', opts, options);
                                    options = [...options, ...cond.options];
                                    //setOptions = true;
                                /* } else {
                                    showHide = false;
                                }  */
                            } else if (cond.then == "show_hide") {
                                showHide = this.checkCondition(cond);
                                //if (field.name == "CONF_DHW_HEADING") console.log('show_hide check condition', cond, showHide);
                            } else if (cond.then == "set_value") {
                                const setPropertyValue = this.checkCondition(cond);
                                //console.log('set value condition', field.label, cond, setPropertyValue);
                                if (setPropertyValue && field.name) {
                                    const storedValue = this.getValueEntryByItemName(field.name);
                                    if (!storedValue) {
                                        this.setDefaultAsFormValue(field.name, cond.value);
                                    }
                                   
                                }
                            } else {
                                console.error('unhandled then in condition', cond);
                            }
                        });
                        setOptions = options.filter(o => o.show).length;

                        //if (options && options.length) console.log('options', options);
                    } else  {
                        //console.log('no field.conditions', field);
                        //setOptions = true;
                    }
                //}
                    //if (field.name == "CONF_DHW_HEADING") console.log('checkCondition result', field.name, options, showHide, field.conditions);
                //if ((setOptions && options && options.length) || showHide) {
                    const nf = {}
                    const addField = Object.assign(nf, field);
                    //addField.show = (setOptions && options && options.length) || showHide;
                    addField.show = setOptions || showHide;
                    if (field.conditions) {
                        addField.isConditional = true;
                        //console.log('got field.conditions / options / showHide', options, showHide);
                        if (options && options.length) {
                            //console.log('remove conditions', addField.conditions, options);
                            delete(addField.conditions);
                            //console.log('add options', options);
                            addField.options = options;
                            //addField.show = true;
                            addField.show = setOptions;
                        } else if (!showHide) {
                            //addField = undefined;
                            addField.show = false;
                        }
                    }
                    if (addField) {
                        //console.log('addField ', addField);
                        entry.fields.push(addField);
                    }
                //}
            });
            this.formFields.push(entry);            
        });
        console.log('this.formFields', this.formFields ); 
    },

    updateFormFields() {
        this.formFields.forEach(step => {
            step.fields.forEach(field => {
                let showHide = true;
                let setOptions = false;
                const originalField = this.getFieldByName(field.name);
                
                    //update the switch
                if (field.name == "CONF_VAR_FORMMODE_SWITCH") {
                    field.show = LoginController.loggedIn;
                } else {
                    if (!LoginController.loggedIn && field.expert) {
                        field.show = false;
                    } else if (LoginController.loggedIn && field.expert && !step.expertMode /*this.formMode == 'basic' */) {
                        field.show = false;
                    } else if (originalField) {
                        if (originalField.conditions) {
                                //set all options hidden
                            if (field.options && field.options.length) field.options.forEach(o => o.show = false);                     
                            originalField.conditions.forEach(cond => {
                                if (cond.then == "set_options") {
                                    this.checkShowOption(cond);
                                    if (cond.options) {
                                        cond.options.forEach(co => {
                                            field.options.find(o => o.uid == co.uid).show = co.show;
                                        });
                                    }
                                } else if (cond.then == "show_hide") {
                                    //console.log("ConfiguratorFormController:updateFormFields:show_hide condition", field.name, cond);
                                    showHide = this.checkCondition(cond);
                                    if (showHide && originalField.conditions.length == 1 && field.options && field.options.length) {
                                           //set all options to show, because there is only one conditions and options are set to show false
                                        field.options.forEach(o => o.show = true);
                                    }
                                } else if (cond.then == "set_value") {
                                    const setPropertyValue = this.checkCondition(cond);
                                    if (setPropertyValue && field.name) {
                                        const storedValue = this.getValueEntryByItemName(field.name);
                                        if (!storedValue) {
                                            this.setDefaultAsFormValue(field.name, cond.value);
                                        } 
                                    }
                                } else {
                                    console.error('unhandled then in condition', cond);
                                }
                            });
                            if (field.options && field.options.length) {
                                showHide = setOptions = field.options.filter(o => o.show).length>0;
                            } else {
                                setOptions = false;
                            }
                        }
                        field.show = setOptions || showHide;
                    }
                }
            });
        });
        //console.log('this.formFields', this.formFields ); 
    },    

    getFieldByName(itemName) {
        let i = 0, item = [];
        while (i < ConfiguratorFormDataService.data.length && !item.length) {
            item = ConfiguratorFormDataService.data[i].fields.filter(e => e.name == itemName);
            i++;
        }
        //console.log('getFieldByName', itemName, item);
        return (item && item.length) ? item[0] : null;
    },

    setDefaultAsFormValue(prop, value) {
        /* let entry = [], i = 0;
        while (i < ConfiguratorFormDataService.values.length && entry.length == 0) {
            entry = ConfiguratorFormDataService.values[i].filter(e => Object.keys(e)[0] == prop);
            i++;
        }  */
        const entry = this.getValueEntryByItemName(prop);
        if (entry.length) {
            //console.log('setDefaultAsFormValue -> entry', entry);
            entry[0][prop] = value;
        }
    },

    updateFormValue(prop, value) {
        //const entry = ConfiguratorFormDataService.values[Store.state.app.activeFormStep].filter(e => Object.keys(e)[0] == prop); 
        const entry = this.getValueEntryByItemName(prop);
        if (entry.length) {
            entry[0][prop] = value;
            const item = this.getFieldByName(prop);
            if (item && item.onupdate_handle) {
                //console.log('item onupdate_handle:', item, item.onupdate_handle);
                //console.log('updateFormValue -> refresh formFields', ConfiguratorFormDataService.values);
                this.resetOnupdateHandleFieldValues(item.onupdate_handle);
                    //this.generateFormFields();
                this.updateFormFields();
            }
            //console.log("ConfiguratorFormController[updateFormValue]: property set", prop, value);
        } else {
            console.error("ConfiguratorFormController[updateFormValue]: property not found", prop, value);
        }  
    },

    updateError(prop, value) {
        const entry = this.getValueEntryByItemName(prop);
        if (entry.length) entry[0].hasError = value;
    },

    getFormValue(item) {
        //console.log('ConfiguratorFormDataService.values', ConfiguratorFormDataService.values);
        //const entry = ConfiguratorFormDataService.values[Store.state.app.activeFormStep].filter(e => Object.keys(e)[0] == item.name); 
        const entry = this.getValueEntryByItemName(item.name);
        
        let val = "";
        if (item.type == 'select_multiple_vertical') {
            val = [];
            if (item && item.options) {
                item.options.forEach(o => {
                    //console.log('ConfiguratorFormDataService->getFormValue: select_multiple_vertical', item.name, entry, o.value);
                    val.push((entry[0][item.name].length && entry[0][item.name].indexOf(o.value)!==-1)?o.value:"");
                });
            }
            //console.log('ConfiguratorFormDataService->getFormValue: select_multiple_vertical val', val);
        } else if (item.name == "CONF_VAR_FORMMODE_SWITCH") {
            //console.log('ConfiguratorFormController[getFormValue] item, entry', item, entry);
            val = ConfiguratorFormController.formMode == "expert" ? 1 : 0;
        } else {
            //console.log('ConfiguratorFormController[getFormValue] item, item.type, entry', item, item.type, entry);            
            val = entry && entry[0]?entry[0][item.name]:"";
        }
        //console.log('getFormValue val', item.name, val);
        const r = {
            value: val
        };
        //console.log('ConfiguratorFormController[getFormValue] return', r);
        return r;
    },

    resetOnupdateHandleFieldValues(updateHandle) {
        updateHandle.forEach(item => {
            const valueEntry = this.getValueEntryByItemName(item);
            const field = this.getFieldByName(item);
            if (field && field.hasError) field.hasError = false;
            if (valueEntry.length && field) ConfiguratorFormDataService.resetFieldvalue(valueEntry, field);
        });
    },

    resetOnExpertModeChangeFieldValues(step) {
        this.formFields[step].fields.forEach(field => {
            if (field.expert) {
                if (field.hasError) field.hasError = false;
                const valueEntry = this.getValueEntryByItemName(field.name);
                if (valueEntry.length && field) ConfiguratorFormDataService.resetFieldvalue(valueEntry, field);
            }
        });
    },

    getReadableValue(item) {
        const entry = this.getValueEntryByItemName(item.name);
        let val = "";
        if (item.type == "select_multiple_vertical" || item.type == "select_multiple_horizontal") {
            item.options.forEach(o => {
                if (entry[0][item.name].length && entry[0][item.name].indexOf(o.value)!==-1) val += (val) ? ", " + o.text : o.text;
            });
        } else if (item.type == "radio_horizontal" || item.type == "radio_vertical" || item.type == "select") {
            const option = item.options.filter(o => entry[0][item.name] && o.value == entry[0][item.name]);
            if (option.length) val = option[0].text;
        } else if (item.type == "bool") {
            val = (entry && entry[0] && entry[0][item.name]) ? ContentController.getContent("TEXT_YES", true) : ContentController.getContent("TEXT_NO", true);
        } else {
            //console.log('ConfiguratorFormController[getFormValue] item, entry', item, entry);
            val = entry && entry[0]?entry[0][item.name]:"not found";
            if (item.unit) val = val + " " + item.unit;
        }
        //console.log('getFormValue val', item.name, val);
        return val;
    },

    getPostValue(item /*, step */) {
        //console.log('ConfiguratorFormDataService.values', ConfiguratorFormDataService.values);
        //const entry = ConfiguratorFormDataService.values[step].filter(e => Object.keys(e)[0] == item.name); 
        const entry = this.getValueEntryByItemName(item.name);
        let val = "";
        if (item.type == 'select_multiple_vertical') {
            val = [];
            item.options.forEach(o => {
                val.push((entry[0][item.name].length && entry[0][item.name].indexOf(o.value)!==-1 && val.indexOf(o.value) == -1)?o.value:"");
            });
        } else {
            //console.log('ConfiguratorFormController[getFormValue] item, entry', item, entry);
            val = entry && entry[0]?entry[0][item.name]:"";
        }
        //console.log('getFormValue val', item.name, val);
        return val;
    },

    getValueEntryByItemName(itemName) {
        let entry = [], i = 0;
        while (i < ConfiguratorFormDataService.values.length && entry.length == 0) {
            entry = ConfiguratorFormDataService.values[i].filter(e => Object.keys(e)[0] == itemName);
            i++;
        }         
        return entry;
    },

    checkIfShowField(/*field*/) {
            //TODO: check if there are conditions on simple inputs
        return true;
    },

    /* getOptionsToShow(field) {
        if (!field.conditions) {
            return field.options;
        } else {
            let r = [];
            field.conditions.forEach(c => {
                const options = this.checkShowOption(c);
                if (options) r = [...r, ...options];
            });
            return r;
        }
    }, */

    checkCondition(condition) {
        //const activeStep = Store.state.app.activeFormStep;
        let result = true;
        for (let key in condition.if) {
                //combine logical AND with others
            if (result && Array.isArray(condition.if[key])) {
                //const entry = ConfiguratorFormDataService.values[activeStep].filter(e => Object.keys(e)[0] == key);
                const entry = this.getValueEntryByItemName(key);
                //console.log('checkCondition: have to check against array', condition.if[key], entry);
                if (entry && entry.length && entry[0] && entry[0][key]) {
                        //logical OR
                    result = condition.if[key].some(c => entry[0][key].indexOf(c) >= 0);
                    //console.log('checkCondition: found?', condition.if[key], entry[0][key], result);
                } else if (!entry[0][key]) {
                    //console.log('no value so far', key);
                    result = false;
                }
            } else if (result) {
                const entry = this.getValueEntryByItemName(key);
                /* if (key == "CONF_VAR_SELECTION_ADDITIONAL") {
                    console.log("ConfiguratorFormController:checkCondition:key,entry", key, entry);
                } */
                if (result && entry.length && !Array.isArray(entry[0][key])) { //entry is a string
                    if (entry[0][key] != condition.if[key]) result = false;
                } else if (result && entry.length && Array.isArray(entry[0][key])) { //entry could be an array
                    if (entry[0][key].indexOf(condition.if[key]) == -1) {
                        result = false;
                    }
                }
            }
        }            
        return result;
    },

    checkShowOption(condition) {
        const show = this.checkCondition(condition);
            //just add show to the condition
        condition.options.forEach(o => o.show = show );
        //return (show?condition.options:null);
            //return all options with show on / off
        //return condition.options;
    },

    getFormPostData() {
        //console.log('ConfiguratorFormController->getFormPostData values', ConfiguratorFormDataService.values);
        //console.log('ConfiguratorFormController->getFormPostData fields', this.formFields);
        let fData = {};
        this.formFields.forEach((step, stepKey) => {
            step.fields.forEach(field => {
                if (field.show && field.name != "CONF_VAR_ADDRESS" && field.name != "CONF_VAR_FORMMODE_SWITCH") {
                    //console.log("ConfiguratorFormController:getFormPostData:field", field);
                    const postValue = this.getPostValue(field, stepKey);
                    if (field.type == 'boolswitch' || postValue) {
                        //console.log('getFormValue added postValue ', field.name, postValue);
                        if (field.type == 'boolswitch' && !postValue) {
                            fData[field.name] = 'false';    
                        } else {
                            fData[field.name] = postValue;
                        }
                    }
                }
            });
        });
        /* ConfiguratorFormDataService.values.forEach(step => {
            step.forEach(property => {
                for(var key in property) {
                    if (key != "CONF_VAR_ADDRESS") {
                        fData[key] = property[key];
                    }
                }
            });
        }); */
        return PostData.build(fData);
    },

    /* getPdfValues() {
            //TODO Quick and Dirty harcoded, replace with values from Server
        const labels = ["Standort / Gebäude", "Heizen / Kühlen", "Warmwasser", "Energiemanagement", "Zusatzdaten"];
        const result = {};
        this.formFields.forEach((step, stepKey) => {
            result[stepKey] = {
                label: labels[stepKey] ? labels[stepKey] : "",
                data: {}
            };
            step.fields.forEach((field, idx) => {
                if (field.show && field.name != "CONF_VAR_FORMMODE_SWITCH") {
                    const readValue = this.getReadableValue(field, stepKey);
                    if (readValue) {
                        if ((field.type != "hidden" && field.type != "boolswitch") || (field.type == "hidden" && field.name == "CONF_VAR_ADDRESS")) {
                            result[stepKey]["data"][idx] = {
                                label: (field.name != "CONF_VAR_ADDRESS" ? (field.type == "radio_horizontal" && !field.label ? readValue : field.label) : "Postleitzahl / Ortschaft"),
                                name: field.name,
                                value: (field.type == "radio_horizontal" && !field.label ? "" : readValue),
                                quickinfo: field.quickinfo,
                                icon: field.icon
                            };
                        }   
                    }
                }
            });
        });
        return result;
    },  */    

    getPostDataForRequest() {
        const result = [];
        this.formFields.forEach((step, stepKey) => {
            step.fields.forEach(field => {
                if (field.name != "CONF_VAR_ADDRESS" && field.name != "CONF_VAR_FORMMODE_SWITCH") {
                    const postValue = this.getPostValue(field, stepKey);
                    if (field.type == 'boolswitch' || postValue) {
                        if (field.type == 'boolswitch' && !postValue) {
                            result.push({ [field.name]: 'false' });    
                        } else {
                            result.push({ [field.name]: postValue });
                        }
                    }
                }
            });
        });
        return result;
    },    

    getWeatherFormPostData() {
        //console.log('ConfiguratorFormController->getFormPostData values', ConfiguratorFormDataService.values);
        //console.log('ConfiguratorFormController->getFormPostData fields', this.formFields);
        let fData = {};
        this.formFields.forEach((step, stepKey) => {
            step.fields.forEach(field => {
                if (field.name == "CONF_VAR_ADDRESS_SEARCHSTR" || field.name == "CONF_VAR_LONGITUDE" || field.name == "CONF_VAR_LATITUDE" || field.name == "CONF_VAR_COUNTRY") {
                    const postValue = this.getPostValue(field, stepKey);
                    if (postValue) {
                        //console.log('getFormValue added postValue ', field.name, postValue);
                        fData[field.name] = postValue;
                    }
                }
            });
        });
        /* ConfiguratorFormDataService.values.forEach(step => {
            step.forEach(property => {
                for(var key in property) {
                    if (key != "CONF_VAR_ADDRESS") {
                        fData[key] = property[key];
                    }
                }
            });
        }); */
        return PostData.build(fData);
    },

    checkStepCompleted(step) {
        let valid = true;
        const mandatories = this.formFields[step].fields.filter(e => e.mandatory === true && e.show === true); //.map(e => e.name);
        mandatories.forEach(field => {
            ConfiguratorFormDataService.values[step].forEach(property => {
                for(var key in property) {
                    if (valid && key == field.name && field.type != 'heading') {
                        //console.log('do form check with ', key, property[key]);
                        if (typeof property[key] === "undefined" || 
                            (field.hasError) ||
                            (field.type == "radio_vertical" && parseFloat(property[key]) == 0) ||
                            (field.type == "number" && ((parseFloat(field.min) && parseFloat(property[key]) < parseFloat(field.min)) || (parseFloat(field.max) && parseFloat(property[key]) > parseFloat(field.max)))) || 
                            (typeof property[key] == "string" && property[key].trim() == "") ||
                            (Array.isArray(property[key]) && (property[key].length == 0 || property[key].filter(option => option != "").length == 0))) {
                            valid = false;
                        }
                        //console.log("ConfiguratorFormController:checkStepCompleted:field", field.name, field.hasError);
                    }
                }
            });
        });
        //console.log("ConfiguratorFormController:checkStepCompleted:valid", valid);
        return valid;
    },

    checkStoredData() {
        ConfiguratorFormDataService.sessionStoredData = JSON.parse(sessionStorage.getItem(this.localStorageFormDataKey));
        console.log('ConfiguratorFormController: set startedOnMobile, checkStoredData', ConfiguratorFormDataService.sessionStoredData);
        if (ConfiguratorFormDataService.sessionStoredData) this.configurationStartedOnMobile();
    },

    storeFormDataToLocalStorage(data) {
        console.log('storeFormDataToLocalStorage', data);
        data = JSON.stringify(data);
        sessionStorage.setItem(this.localStorageFormDataKey, data);
    },

    removeFormDataFromLocalStorage() {
        sessionStorage.removeItem(this.localStorageFormDataKey);
        sessionStorage.removeItem(this.localStorageFormExpertModeDataKey);
        ConfiguratorFormDataService.sessionStoredData = null;
    },    

    getSessionStoredValue(fieldName) {
        return ConfiguratorFormDataService.getStoredValue(fieldName);
    },

    storeFormExpertModesToLocalStorage() {
        let data = this.formFields.map(step => step.expertMode);
        data = JSON.stringify(data);
        console.log("ConfiguratorFormController:storeFormExpertModeToLocalStorage", data);
        sessionStorage.setItem(this.localStorageFormExpertModeDataKey, data);
    },

    getFormExpertModesFromLocalStorage() {
        //console.log("ConfiguratorFormController:getFormExpertModesFromLocalStorage", JSON.parse(sessionStorage.getItem(this.localStorageFormExpertModeDataKey)));
        return JSON.parse(sessionStorage.getItem(this.localStorageFormExpertModeDataKey));
    }

};
  
export default ConfiguratorFormController;