import { Api } from "@/api";
import axios from "axios"; 
import Store from "@/store";

/* import router from "@/router";
import Store from "@/store";
import Backend from "@/utils/Backend.js"; */
//import { dataFormCustomerDe, dataFormExpertDe } from "@/data";

const ConfiguratorFormDataService = {

    data: [],
    values: [],
    sessionStoredData: null,

    valueDefaults: {
      "number": 0,
      "radio_horizontal": "",
      "radio_vertical": 0,
      "select_multiple_vertical": []
    },

    async fetchFormData(mode, formMode, token) {

        const url = `${Api.BACKEND_URL}${mode=="expert" && formMode=="expert"?Api.FORM_EXPERT:Api.FORM_CUSTOMER}&lang=${Store.getters.gLanguage}`;
        let response;
        try {
          if (token) {
            console.log('fetchFormData with token: ', token);
            response = await axios.create({ withCredentials: false }).get(url, {
              headers: {
                'Authorization': `Token ${token}`
            }});
          } else {
            response = await axios.create({ withCredentials: false }).get(url);
          }
          if (response.status == 200) {
            console.log("ConfiguratorFormDataService:fetchFormData:response", response);
            this.data = response.data;
          }
        } catch (err) {
          console.error(err);
        }

        this.createValues();
        
        return this.data;
    },

    async weatherPrefetch(formData) {
      const url = Api.BACKEND_URL + Api.WEATHER_PREFETCH;
      let response;
      try {
        response = await axios.create({ withCredentials: false }).post(url, formData);
        //console.log('ConfiguratorFormDataservice->weatherPrefetch respone', response);
        if (response.status == 200) {
          //this.data = response.data;
          return response;
        }
      } catch (err) {
        console.error(err);
      }
    },    

    async postFormData(formData, expertValuesEntered, token) {
      // const url = Api.BACKEND_URL + (expertValuesEntered ? Api.POST_FORM_EXPERT : Api.POST_FORM_CUSTOMER);

      const url = `${Api.BACKEND_URL}${expertValuesEntered ? Api.POST_FORM_EXPERT : Api.POST_FORM_CUSTOMER}&lang=${Store.getters.gLanguage}`;
      console.log('ConfiguratorFormDataservice->postFormData token', token, expertValuesEntered, url);
      let response;
      try {
        if (token) {
          response = await axios.create({ withCredentials: false }).post(
            url, 
            formData, {
              headers: {
                'Authorization': `Token ${token}`
            }
          });
        } else {
          response = await axios.create({ withCredentials: false }).post(url, formData);
        }

        if (response.status == 200) {
          //this.data = response.data;
          return response;
        }
      } catch (err) {
        console.error(err);
      }
    },

    injectGoogleHiddenFields() {
      const gFields = [{
        "name": "CONF_VAR_LATITUDE",
        "type": "hidden"
      },{
        "name": "CONF_VAR_LONGITUDE",
        "type": "hidden"
      }];
      this.data[0].fields.splice(1,0, gFields[0], gFields[1]);
    },

    removeDoubleFields() {
      this.data[0].fields[7].conditions.splice(2,2);
    },

    createValues() {
      this.values = [];
      this.data.forEach(d => {
          this.values.push(this.createFieldValues(d));
      });
      //console.log('this.values', this.values);
    },

    resetValues() {
      this.data.forEach((step, idx) => {
        step.fields.forEach((field) => {
          const entry = ConfiguratorFormDataService.values[idx].filter(e => Object.keys(e)[0] == field.name);
          entry[0][field.name] = (typeof field.default !== 'undefined'?field.default:this.valueDefaults[field.type]);
        });
      });
    },

    resetFieldvalue(valueEntry, field) {
      //console.log('ConfiguratorFormDataservice resetFieldvalue', valueEntry, field);
      valueEntry[0][field.name] = (typeof field.default !== 'undefined'?field.default:this.valueDefaults[field.type]);
    },

    createFieldValues(data) {
      const r = [];
      data.fields.forEach(d => {
        let val = typeof d.default !== 'undefined'?d.default:this.valueDefaults[d.type];
        const storedValue = this.getStoredValue(d.name);
        if (storedValue) {
          val = storedValue;
        }
        if (d.type == "number" && ((d.min && val < d.min) || (d.max && val > d.max))) val = d.min;
        r.push({
          [d.name]: val
        });
      });
      return r;
    },

    getStoredValue(fieldName) {
      if (this.sessionStoredData) {
        let entry = [], i = 0;
        while (i < this.sessionStoredData.length && entry.length == 0) {
            entry = this.sessionStoredData[i].filter(e => Object.keys(e)[0] == fieldName);
            i++;
        }         
        return entry.length ? entry[0][fieldName] : null;
      } else {
        return null;
      }
    },

  };
  
  export default ConfiguratorFormDataService;