<template>
  <div class="page page-request" ref="domRequest" >
    <div class="request-steps">
      <ul>
        <li data-index="1" class="request__step" :class="{'is-active': requestStep == 1, 'is-done': 1 < requestStep }">
          <button class="button--dense" @click="handleStepButtonClick(1)">
            <span class="request-step__number">1</span>
            <span class="request-step__label request-step__label--desktop">{{ getContent('CONF_TEXT_PARTNER_STEP_1', true) }}</span>
            <span class="request-step__label request-step__label--mobile">{{ getContent('CONF_IDMPARTNER', true) }}</span>
          </button>
        </li>
        <li data-index="2" class="request__step" :class="{'is-active': requestStep == 2 }">
          <button class="button--dense" @click="handleStepButtonClick(2)">
            <span class="request-step__number">2</span>
            <span class="request-step__label request-step__label--desktop">{{ getContent('CONF_TEXT_PARTNER_STEP_2', true) }}</span>
            <span class="request-step__label request-step__label--mobile">{{ getContent('CONF_PERSONAL_DATA', true) }}</span>
          </button>
        </li>
      </ul>
    </div>
    <RequestPartner :requestStep="requestStep" :selectedPartner="partner" @onPartnerSelected="handlePartnerSelected"/>
    <RequestData :requestStep="requestStep" :partner="partner" :item="item" :mode="mode" :title="title"/>
    <div class="request-dark-background" v-if="requestStep == 2"></div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { RequestPartner, RequestData } from "./Request/";
import { ContentController } from "@/controller";

export default {
  name: "PageRequest",
  components: {
    RequestPartner,
    RequestData
  },
  computed: {
    ...mapGetters(["gSize"]),
  },
  data() {
    return {
      requestStep: 1,
      partner: null
    }
  },
  watch: {
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      page: {
        type: Object,
        default: null
      },
      item: {
        type: Object,
        default: null
      },
      title: {
        type: String,
        default: ""
      }
  },
  created() {
  },
  methods: {
    handlePartnerSelected(partner) {
      this.partner = partner;
      this.requestStep = 2;
    },
    handleStepButtonClick(index) {
      if (this.requestStep == 2 && index == 1) {
        this.requestStep = 1;
        console.log("Request:handleStepButtonClick:partner", this.partner);
      }
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    } 
    /* onPlacesChange() {
      GooglePlacesController.fetchPlaces(this.placesSearch);
    },
    onPlaceChange(country, lat, lng, address) {
      this.setPlacesValues(country, lat, lng, address);
      this.searchOrganizations();
    },
    onPlacesReset() {
      this.setPlacesValues("", "", "", "");
      this.resetForm();
    },    
    onFormSubmit() {
      this.searchOrganizations();
    },
    handleClick(ev) {
      this.$emit("onClick", ev);
    },
    setPlacesValues(country, lat, lng, address) {
      this.form.country = country;
      this.form.lat = lat;
      this.form.lng = lng;
      this.form.address = address;
      this.placesSearch = address;
      this.setMapCenter();
    },    
    setMapCenter() {
      this.mapCenter = {
        lat: this.form.lat,
        lng: this.form.lng
      }
    }, */
/*    getPropertiesCollapsedHeight() {
      return 378; //(this.gSize.screen.width<1024?378:280);
    }, */
    /* handleListitemClick(item) {
      if (!item.notused && this.itemDetailHasProperties(item)) {
        this.selected = item.uniqeId;
        this.detailSelected = item.detail;
        SmoothScrollTo.scroll(this.$refs.domSystem);
      }
    }, 
    itemDetailHasProperties(item) {
      return Object.keys(item.detail).length > 0;
    }, */
    /* async searchOrganizations() {
      this.partners = await RequestController.searchOrganizations(this.form.lat, this.form.lng);
      this.setMarkers();
    },
    handleItemClick(idx) {
      if (idx == this.expanded) this.expanded = -1
      else this.expanded = idx;
      //this.setMarkers();
    },
    handleMarkerClicked(idx) {
      this.expanded = idx;
      if (idx != -1) SmoothScrollTo.scroll(this.$refs.domPermanent[idx]);
    },
    getHeight(idx) {
      let h = 0;
      if (idx == this.expanded) {
        h = this.$refs.domFlexibleInner && this.$refs.domFlexibleInner[idx]?this.$refs.domFlexibleInner[idx].getBoundingClientRect().height:0;
      } 
      return h!=0?"height: " + h + "px;":"height: 0;";
    },
    onSearchClick() {
      this.searchOrganizations();
    },
    setMarkers() {
      this.markers = this.partners.map((partner, idx) => {
        return {
          id: idx,
          position: {
            lat: partner.lat,
            lng: partner.lng
          },
          selected: this.expanded == idx
        }
      });
    }, */
  },
};
</script>
