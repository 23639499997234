<template>
  <div class="request-step request-step--data" v-show="requestStep == 2">
    <div class="system-partner">
      <div class="cardlist__item" data-index="0">
        <div class="cardlist-item__inner">
          <div class="cardinfo">
            <p class="cardinfo__label">
              <span>{{ getContent('CONF_TEXT_PARTNER_SYSTEM_SELECTED', true) }}</span>
            </p>
          </div>
          <div class="cardlist__itemlist">
            <div v-for="(subitem, idx) in item.pages[0].summary"
              :key="subitem.uniqeId + '_' + idx"
            >
                <Card
                  :key="subitem.uniqeId"
                  :data-index="idx"
                  :itemIndex="idx"
                  :item="subitem"
                  :mainPumpFocusOn="expanded"
                  :mode="mode"
                  :mainPumpCard="idx == 0"
                  @toggleClick="expanded = !expanded"
                  :alwaysExpanded="true"
                />
                <Card 
                  v-for="(linkedcard, subidx) in subitem.subcards"
                  :key="linkedcard.uniqeId"
                  :data-index="idx"
                  :itemIndex="idx"
                  :data-linked-index="subidx"
                  :item="linkedcard"
                  :parent="subitem"
                  :mainPumpFocusOn="expanded"
                  :mode="mode"
                  :cardIndex="false"
                  :alwaysExpanded="true"
                />
            </div>
          </div>
        </div>
      </div>

      <div class="partner-info" v-if="partner">
        <div class="partner-info__header">
          <p>{{ getContent('CONF_TEXT_PARTNER_PARTNER_SELECTED', true) }}</p>
        </div>
        <div class="partner-info__content">
          <p class="partner__name"><strong>{{ partner.name }}</strong></p>
          <p>{{ partner.address }}</p>
          <p>Distanz zur Ihrem Standort: {{ partner.distance }}</p>
        </div>
      </div>
    </div>
    <div class="request-form" v-show="!submitSuccess">
      <h3 class="form-legend col12">{{ getContent('CONF_PERSONAL_DATA', true)}}</h3>
      <div class="form-item"
        v-for="item in requestFormData"
        :key="item.uid"
        :data-type="item.type"
        :class="{ 'col6': item.size == 'half', 'col12': item.size != 'half' }"
      >
        <InputHidden v-if="item.type=='hidden'" :item="item" @onChange="onChange" />
        <RadioGroup v-if="(item.type=='radio_horizontal' || item.type=='radio_vertical')" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" />
        <CheckGroup v-if="item.type=='select_multiple_vertical'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" />
        <CheckButtonGroup v-if="item.type=='select_multiple_horizontal'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" />
        <InputField v-if="item.type=='number' || item.type=='text' || item.type=='phone' || item.type=='email'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" />
        <BoolButton v-if="item.type=='bool'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" />
        <BoolSwitch v-if="item.type=='boolswitch'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" />         
        <Heading v-if="item.type=='heading'" :item="item" />
        <SelectField v-if="item.type=='select'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" />
        <CheckBox v-if="item.type=='checkbox'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" />
        <TextField v-if="item.type=='textarea'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" />
      </div>
      <div class="form-item col12" v-html="getContent('TEXT_REQUEST_FORMULAR_DISCLAIMER', false)">
      </div>
      <div class="form-action col12">
        <button type="submit" class="submit button--primary" name="request-form-submit" :disabled="sendingDisabled || submitting" @click="onClickSubmit($event)" ref="domButtonSubmit">{{ getContent('TEXT_SEND_REQUEST', true) }}
          <span v-if="submitting" v-html="icons.loaderSVG" class="form-loader"></span>
        </button>
      </div>
    </div>
    <div class="request-submit-success" v-show="submitSuccess">
      <header>
        <i class="svgicon svgicon--success" v-html="icons.success"></i>
        <h3 v-html="getContent('TEXT_REQUEST_FORMULAR_CONFORMATION', false)"></h3>
      </header>
      <div class="content" v-html="getContent('TEXT_REQUEST_FORMULAR_MAIL', false)">
      </div>
      <footer>
        <button class="button--primary" @click="onClickNewProject" ref="domButtonNewProject">{{ getContent('TEXT_START_NEW_PROJECT', true) }}</button>
        <button class="button--secondary" @click="onClickBackToProject" ref="domButtonBackToProject">{{ getContent('TEXT_BACK_TO_MY_CONFIGURATION', true) }}</button>
      </footer>
    </div>
  </div>
</template>

<script>

import { EventBus } from "@/bus/eventbus.js";
import { mapGetters } from "vuex";
import { Card } from "../../../list";
import { RequestController, ConfiguratorFormController/* , PdfController */, ContentController } from "@/controller";
import { InputHidden, InputField, SelectField, RadioGroup, CheckGroup, CheckButtonGroup, BoolButton, BoolSwitch, Heading, CheckBox, TextField } from "@/views/home/components/fields";
import Uniqe from "@/utils/Uniqe.js";

export default {
  name: "RequestData",
  components: {
    Card,
    InputField,
    InputHidden,
    SelectField,
    RadioGroup,
    CheckGroup,
    CheckButtonGroup,
    BoolButton,
    BoolSwitch,
    Heading,
    CheckBox,
    TextField
  },
  computed: {
    ...mapGetters(["gSize"]),
  },
  data() {
    return {
      expanded: true,
      sendingDisabled: true,
      requestFormData: [
        {
            "name": "name",
            "type": "text",
            "mandatory": true,
            "label": this.getContent("TEXT_NAME", true),
            "uid": Uniqe.getUniqe()
        },
        {
            "name": "email",
            "type": "email",
            "mandatory": true,
            "label": this.getContent("TEXT_MAIL_ADDRESS", true),
            "uid": Uniqe.getUniqe()
        },
        {
            "name": "phone_number",
            "type": "phone",
            "mandatory": false,
            "label": this.getContent("TEXT_TELEPHONE_NUMBER", true),
            "uid": Uniqe.getUniqe()
        },
        {
            "name": "realization_planned_in_months",
            "type": "select",
            "mandatory": false,
            "uid": Uniqe.getUniqe(),
            "label": this.getContent("TEXT_PLANNED_REALISATION", true),
            "options": [
            {
                text: this.getContent("CONF_REQ_SELECT", true),
                value: 0
            },
            {
                text: this.getContent("USERREQUEST_PERIOD_1", true),
                value: 1
            },
            {
                text: this.getContent("USERREQUEST_PERIOD_2", true),
                value: 2
            },
            {
                text: this.getContent("USERREQUEST_PERIOD_3", true),
                value: 3
            },
            {
                text: this.getContent("USERREQUEST_PERIOD_4", true),
                value: 4
            }
            ]
        },
        {
            "name": "notes",
            "type": "textarea",
            "mandatory": false,
            "uid": Uniqe.getUniqe(),
            "label": this.getContent("TEXT_ANNOTATION", true)
        },
        {
            "name": "accept_mail",
            "type": "checkbox",
            "mandatory": false,
            "uid": Uniqe.getUniqe(),
            "label": this.getContent("CONF_ACCEPT_MAIL", true)
        },
      ],
      submitSuccess: false,
      icons: {
        success: '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.0001 73.3334C58.4096 73.3334 73.3334 58.4095 73.3334 40C73.3334 21.5905 58.4096 6.66669 40.0001 6.66669C21.5906 6.66669 6.66675 21.5905 6.66675 40C6.66675 58.4095 21.5906 73.3334 40.0001 73.3334Z" stroke="#3DB749" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M53.3668 33.3333L36.6667 50.0333L26.6667 40.0333" stroke="#3DB749" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        loaderSVG: '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg>',
      },
      submitting: false
    }
  },
  watch: {
    item: function() {
      RequestController.setItem(this.item);
    },
    partner: function() {
      RequestController.setPartner(this.partner);
    }
  },
  props: {
    mode: {
      type: String,
      default: ""
    },      
    requestStep: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: null
    },
    partner: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ""
    }
  },
  created() {
      //set the formData in Requestcontroller to have the same one 
    RequestController.setItem(this.item);
    RequestController.setPartner(this.partner);
    RequestController.requestFormData = this.requestFormData;
    this.submitSuccess = RequestController.getFormSubmitted();
    //EventBus.$on("onPdfGenerated", () => {
      //if (this.submitting) this.submitRequest();
      /* this.projectPdf.pdf = PdfController.pdf;
      this.projectPdf.pdfFilename = PdfController.pdfFilename;
      console.log("DetailFooter:onPdfGenerated", this.projectPdf.pdf);
      if (this.projectPdf.pdf) this.creatingProject = false; */
    //});    
  },
  methods: {
    getFormValue(item) {
      const el = RequestController.requestFormValues.filter(v => v.name == item.name);
      return (el && el.length) ? { value: el[0].value } : { value: "" };
    },
    onChange(ev, item, value) {
      this.setValueByItem(item, value);
      this.checkMandatory();
    },
    setValueByItem(item, value) {
      const el = RequestController.requestFormValues.filter(v => v.name == item.name);
      if (el && el.length) el[0].value = value;
    },
    checkMandatory() {
      const missing = this.requestFormData.filter(item => {
        return item.mandatory && !this.getFormValue(item).value;
      });
      this.sendingDisabled = missing.length>0;
    },
    async onClickSubmit() {
      this.submitting = true;
      //PdfController.directDownload = false;
      //EventBus.$emit("onCreateProjectPdf");
      this.submitRequest();
    },
    onClickNewProject() {
      this.$dialog({
        title: this.getContent("CONF_DELETE", true), 
        text: this.getContent("CONF_DELETE_EVERYTHING", false),
        modal: true,
        onOk: () => {
          ConfiguratorFormController.removeFormDataFromLocalStorage();
          RequestController.setFormSubmitted(false);
          window.location = "/";
        }
      });
    },
    onClickBackToProject() {
      EventBus.$emit("onDetailBackButtonClick");
        //TODO reset here?
      RequestController.setFormSubmitted(false);
    },
    onGdprClick() {
      EventBus.$emit("onShowGdpr");
    },
    async submitRequest() {
      const response = await RequestController.postRequest();
      if (response) {
        this.submitSuccess = response.status >= 200 && response.status < 300;
        RequestController.setFormSubmitted(this.submitSuccess);
        RequestController.resetFormValues();
      }
      this.submitting = false;
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }        
  },
};
</script>
