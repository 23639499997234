<template>
  <div class="form-property" :class="{ 'is-disabled': disabled, 'has-error': item.hasError }">
    <label :for="'input'+item.name">{{ item.label }} <Quickinfo v-if="item.quickinfo" :item="item"/></label>
    <p class="form-error" v-show="item.hasError" v-html="errorMsg"></p>
    <input 
      :type="item.type" 
      :id="'input'+item.name" 
      v-model="value" 
      :name="item.name" 
      @change="onChange($event, item)" 
      @keydown="onKeyDown($event)"
      :class="{'has-placeholder': item.unit != '', 'is-disabled': disabled}"
      :min="(item.min?item.min:null)" 
      :max="(item.max?item.max:null)" 
      :step="(item.step?item.step:null)"
      :disabled="disabled"
      inputmode="text"
      ref="domInput"
    />  
    <span v-if="item.unit" class="placeholder placeholder--right">{{ item.unit }}</span>
  </div>
</template>

<script>
import { EventBus } from "@/bus/eventbus.js";
import { Quickinfo } from "@/components/";
import { ContentController } from "@/controller";

export default {
  name: "InputField",
  components: {
    Quickinfo
  },
  computed: {
  },
  props: {
      handles: {
        type: Array,
        default: null
      },
      item: {
        type: Object,
        default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      value: 0,
      errorMsg: "Fehler bei der Eingabe"
    }
  },
  watch: {
    storedValue(val) {
      //console.log('input watch storedValue', val.value);
      this.value = val.value;
    }
  },
  created() {
    this.value = this.storedValue.value;
    EventBus.$on("onFormError", () => this.checkError());
    this.errorMsg = ContentController.getContent("ERROR_MSG_MANDATORY");
  },
  methods: {
    onKeyDown(ev) {
      if (ev.key == "Enter") {
        ev.preventDefault();
      }
    },
    onChange(ev) {
      this.checkError();
      this.$emit("onChange", ev, this.item, this.value.toString());
    },
    checkError() {
      //this.value = this.value.replace(".", ",");
      if (this.item.show) {
        this.item.hasError = false;
        if (this.item.mandatory && !this.value) {
          this.item.hasError = true;
          this.errorMsg = ContentController.getContent("ERROR_MSG_MANDATORY");
        }
        if (this.item.min && this.item.min > this.value) {
          this.item.hasError = true;
          this.errorMsg = ContentController.getContent("ERROR_MSG_MIN") + "(" + this.item.min + ")";
        }
        if (this.item.max && this.item.max < this.value) {
          this.item.hasError = true;
          this.errorMsg = ContentController.getContent("ERROR_MSG_MAX") + "(" + this.item.max + ")";
        }
        if (this.item.step && !this.item.hasError && this.$refs.domInput) {
          const valid = this.$refs.domInput.checkValidity();
          if (!valid) {
            this.item.hasError = true;
            this.errorMsg = ContentController.getContent("CONF_STEP_ERROR") + "(" + this.item.step + ")";
          }
        }
      } else {
        this.item.hasError = false;
      }
      this.$emit("onErrorChange", this.item.name, this.item.hasError);
    },    
  },
};
</script>