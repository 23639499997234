
import Store from "@/store";

import { ContentDataService } from "@/dataservice";

const ContentController = {
    // data: {},
    ok: false,
    pseudo: document.createElement("div"),

    async fetchData() {
        //console.log('ContentController:fetchData');
        const response = await ContentDataService.fetchData();
        if (response.data && response.data.result) {
            // this.data = response.data.result;
            Store.dispatch("setContentData", response.data.result)
            this.ok = true;
        }
        console.log('ContentController:fetchData:data', Store.getters.gContentData);
    },

    getContent(key, clean) {
        //console.log('ContentController:getContent:key, content', key, this.data[key], clean);
        if (clean) {
            //return this.data[key].replace(/<\/?[^>]+(>|$)/g, "");
            // this.pseudo.innerHTML = this.data[key];
            this.pseudo.innerHTML = Store.getters.gContentData[key];
            return this.pseudo.textContent || this.pseudo.innerText || "";            
        } else {
            // return this.data[key];
            return Store.getters.gContentData[key];
        }
    }

};
  
export default ContentController;