<template>
  <div class="overlay" :class="{ 'is-active': active, 'is-visible': visible }">
    <div class="languagebox" :style="style" :class="{ 'is--active': active, 'is--visible': visible }" @click.self="handleWrapperClick">
      
      <button class="button--icon button--close" @click="handleCloseClick">
        <i class="svgicon svgicon--close">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.3334 2.5465L17.4534 0.666504L10 8.11984L2.54669 0.666504L0.666687 2.5465L8.12002 9.99984L0.666687 17.4532L2.54669 19.3332L10 11.8798L17.4534 19.3332L19.3334 17.4532L11.88 9.99984L19.3334 2.5465Z"
              fill="white" />
          </svg>
        </i>
      </button>

      <div class="languagebox__inner">
        <header class="languagebox__header">
          <h3>
            {{ getContent('CONF_LANGUAGE', true) }}
          </h3>
        </header>
      
        <div class="form-property">
          <div class="radio-group radio-group--vertical">
            
            <div 
            class="radio-group__item" 
            v-for="option in languageOptions.options" 
            :key="option.uid" 
            :class="{ 'is-selected': option.value === selectedLanguage, 'is-hidden': !option.show }">

              <input 
              type="radio" 
              :id="'language_' + option.value" 
              :name="languageOptions.name" 
              :value="option.value" 
              v-model="selectedLanguage" 
              :disabled="disabled" />
          
              <label :for="'language_' + option.value">{{ option.text }}</label>

            </div>
          </div>
        </div>
        
        <div class="form-property form-property--action">  
            <button 
            v-if="!submitting" 
            class="button--primary language-save" 
            type="submit" 
            @click.prevent="onSubmit" 
            :disabled="submitting">
              
              {{ getContent('CONF_CHOOSE_LANGUAGE', true) }}
            
            </button>
            <span v-if="submitting"  v-html="loaderSVG" class="form-loader"></span>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions  } from "vuex";
import { ContentController } from "@/controller";

export default {
  name: 'LanguageForm',
  data() {
    return {
      visible: false,
      active: false,
      disabled: false,
      submitting: false,
      loaderSVG: '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg>',
      selectedLanguage: '',
      languageOptions: {
        label: 'Choose Language',
        name: 'language',
        options: [
          { uid: 0, value: 'DE', text: 'Deutsch', show: true },
          { uid: 1, value: 'EN', text: 'English', show: true },
          { uid: 2, value: 'ES', text: 'Español', show: true },
          { uid: 3, value: 'IT', text: 'Italiano', show: true },
        ],
      },
      
    };
  },
  computed: {
    ...mapGetters(["gLanguage"]),
    style() {
      return this.active ? 'display: block;' : 'display: none;';
    },
  },
  methods: {
    ...mapActions(['setLanguage']),
    async onSubmit() {
      this.enableSubmitLoader();
      this.setLanguage(this.selectedLanguage);
      ContentController.fetchData();
      this.disableSubmitLoader();
      this.doClose();
    },
    initShow() {
      this.selectedLanguage = this.gLanguage;
    },
    handleWrapperClick() {
      this.doClose();
    },
    handleCloseClick() {
      this.doClose();
    },
    doClose() {
      this.$languageForm.close();
    },
    enableSubmitLoader() {
      this.submitting = true;
    },
    disableSubmitLoader() {
      this.submitting = false;
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }   
  },
};
</script>
