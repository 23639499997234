<template>
  <div :class="classObj" class="app-wrapper" >
    <HeaderMain v-if="contentFetched"/>
    <StepNavigation v-if="$route.meta.displayStepNavigation" :activeFormStep="activeFormStep" :class="{ 'is-hidden': showDetail }" :data-detail-active-page="activeDetailPage" />
    <AppMain v-if="contentFetched"/>
  </div>
</template>

<script>
import { mapGetters, mapActions} from "vuex";
import { AppMain, HeaderMain, StepNavigation } from "./components";
import { EventBus } from "@/bus/eventbus.js";
import initResize from "@/utils/resize";
import { LoginController, ConfiguratorFormController, ContentController } from "@/controller/";

export default {
  name: "Layout",
  metaInfo: {
    title: "iDM Konfigurator",
  },  
  components: {
    AppMain,
    HeaderMain,
    StepNavigation,
  },
  data() {
    return {
      rAFScrollTicking: false,
      activeFormStep: 0,
      contentFetched: false
    };
  },
  computed: {
    ...mapGetters(["gSize", "gLanguage"]),
    device() {
      return this.$store.state.app.device;
    },
    showDetail() {
      return this.$store.state.app.showDetail;
    },
    activeDetailPage() {
      return this.$store.state.app.detailPage;
    },
    classObj() {
      return {
        mobile: this.device === "mobile",
        landscape: this.gSize.screen.width>=this.gSize.screen.height,
        portrait: this.gSize.screen.width<this.gSize.screen.height,
        "view--results": this.$store.state.app.displayResult,
        "has-edit-form": this.$store.state.app.editFormOnResult,
        "is-expert": this.$store.state.app.mode=='expert'
      };
    },
  },
  async created() {
    console.log("route", this.$route);
    
    //set language
    this.setLanguage(this.gLanguage)
    const html = document.documentElement;
    html.setAttribute('lang', this.gLanguage);

    EventBus.$on("doResize", () => {
      this.checkResize();
    });
    initResize();
    this.checkLogin();
    await ContentController.fetchData();
    this.contentFetched = ContentController.ok;
    this.checkStoredData();
    this.addBodyClickHandler();
  },
  mounted() {
    this.checkResize();
  },
  methods: {
    ...mapActions(['setLanguage']),
    checkResize() {
      this.$store.dispatch("setSize", {
        screen: {
          width: this.getWindowWidth(),
          height: this.getWindowHeight()
        }
      });
    },
    getWindowWidth() {
      return ( window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    },
    getWindowHeight() {
      return ( window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    },
    scrollUpdate() {
      this.rAFScrollTicking = false;
      EventBus.$emit("onScrollWheel", this.scrollWheel);
    },
    handleLogout() {
      LoginController.doLogout();
    },
    checkLogin() {
      LoginController.checkLogin();
    },
    checkStoredData() {
      ConfiguratorFormController.checkStoredData();
    },
    addBodyClickHandler() {
      /* document.body.addEventListener("click", ev => {
        const composed = ev.composedPath();
        if (composed) {
          const close = composed.filter(el => el.classList.contains('button--tooltip-open'));
          if (!close) {
            this.$toolip.close();
          }
        }
      }); */
    }
  }
};
</script>
