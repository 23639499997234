<template>
  <div class="step-navigation" v-show="((isDesktop || !showStartOnMobile) && !displayGdpr )">
    <nav>
      <ul ref="domStepNavigationList">
        <li 
          v-for='(step,index) in steps'
          :key='index'
          :class="{ 'is-active': getIsActive(index), 'is-done': formStepsDone > index }" 
        >
          <button :disabled="(!displayResult && formStepsDone < index)" :title="getContent(step.title, true)" class="button--icon" @click="handleStepButtonClick($event, index)">
            <i class="svgicon svgicon-step" v-html="step.icon"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { ContentController } from "@/controller";
import { EventBus } from "@/bus/eventbus.js";
import { ConfiguratorFormController } from "@/controller";
import { dataSteps } from "@/data";

export default {
  name: "StepNavigation",
  data() {
    return {
      desktopSize: 1024,
      displayGdpr: false
    };
  },  
  computed: {
    steps() {
      return dataSteps;
    },
    activeFormStep() {
      return this.$store.state.app.activeFormStep;
    },
    displayResult() {
      return this.$store.state.app.displayResult;
    },
    formStepsDone() {
      return this.$store.state.app.formStepsDone;
    },
    editFormOnResult() {
      return this.$store.state.app.editFormOnResult;
    },
    isDesktop() {
      console.log("StepNavigation:isDesktop,showStartOnMobile", this.$store.state.app.size.screen.width >= this.desktopSize, this.showStartOnMobile);
      return this.$store.state.app.size.screen.width >= this.desktopSize;
    },
    showStartOnMobile() {
      if (this.$store.state.app.size.screen.width < this.desktopSize && !this.$store.state.app.startedOnMobile) {
        return true;
      } else if (this.$store.state.app.size.screen.width < this.desktopSize && this.$store.state.app.startedOnMobile) {
        return false;
      } else {
        return true;
      }
    },    
  },
  props: {
  },
  watch: {
  },
  created() {
    EventBus.$on("onShowGdpr", () => {
      this.displayGdpr = true;
    });    
    EventBus.$on("onHideGdpr", () => {
      this.displayGdpr = false;
    });     
  },
  methods: {
    handleStepButtonClick(ev, index) {
      if (ConfiguratorFormController.checkStepCompleted(this.$store.state.app.activeFormStep)) {
        ConfiguratorFormController.setStep(index, true);
        //console.log('handleStepButtonClick', index);
      } else {
        EventBus.$emit("onFormError");
      }
      if (this.$refs.domStepNavigationList) this.$refs.domStepNavigationList.querySelectorAll('button').forEach(b => b.blur());
    },
    getIsActive(index) {
      return (!this.displayResult && this.activeFormStep == index) || (this.editFormOnResult && this.activeFormStep == index);      
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }   
  }
};
</script>
