<template>
  <div class="configurator-form" ref="domConfiguratorForm">
    <form @submit.prevent="onFormSubmit">
      <h3 class="form-legend col12">{{ formData["title"] }}</h3>
      <div class="form-item col12" v-if="activeStep==0">
        <GoogleAutoComplete @onChange="onPlaceChange" @onReset="onPlacesReset" :storedValue="placesSearch" /> 
      </div>
      <div class="form-item"
        v-for="(item) in formData.fields"
        :key="item.uid"
        :data-type="item.type"
        :data-name="item.name"
        :data-index="item.index"
        :id="'formItem_' + item.uid"
        :class="{ 'col6': item.size == 'half', 'col12': item.size != 'half', 'is-hidden': !item.show, 'form-item-separated': item.name == 'CONF_VAR_FORMMODE_SWITCH' }"
      >
        <BoolSwitch :id="'boolSwitch_' + item.uid" v-if="item.name == 'CONF_VAR_FORMMODE_SWITCH'" :item="formModeSwitch" @onChange="handleFormModeChange" :storedValue="{ value: formData['expertMode'] }" :disabled="activeStep == 0 && placesSearch == ''" @onCreated="handleComponentCreated"/>
        <InputHidden :id="'inputHidden_' + item.uid" v-if="item.name != 'CONF_VAR_FORMMODE_SWITCH' && item.type=='hidden'" :item="item" @onChange="onChange" @onCreated="handleComponentCreated" />
        <RadioGroup :key="item.uid" :id="'radioGroup_' + item.uid" v-if="item.name != 'CONF_VAR_FORMMODE_SWITCH' && (item.type=='radio_horizontal' || item.type=='radio_vertical')" :item="item" @onChange="onChange" @onErrorChange="onErrorChange" :storedValue="getFormValue(item)" :disabled="activeStep == 0 && placesSearch == ''" @onMountedConditional="onMountedConditional" @onCreated="handleComponentCreated" />
        <CheckGroup :id="'checkGroup_' + item.uid" v-if="item.name != 'CONF_VAR_FORMMODE_SWITCH' && item.type=='select_multiple_vertical'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" :disabled="activeStep == 0 && placesSearch == ''" @onCreated="handleComponentCreated" />
        <CheckButtonGroup :id="'checkButtonGroup_' + item.uid" v-if="item.name != 'CONF_VAR_FORMMODE_SWITCH' && item.type=='select_multiple_horizontal'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" :disabled="activeStep == 0 && placesSearch == ''" @onCreated="handleComponentCreated" />
        <InputField :id="'inputField_' + item.uid" v-if="item.name != 'CONF_VAR_FORMMODE_SWITCH' && item.type=='number'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" :disabled="activeStep == 0 && placesSearch == ''"  @onCreated="handleComponentCreated" />
        <BoolButton v-if="item.name != 'CONF_VAR_FORMMODE_SWITCH' && item.type=='bool'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" :disabled="activeStep == 0 && placesSearch == ''" @onCreated="handleComponentCreated" />
        <BoolSwitch v-if="item.name != 'CONF_VAR_FORMMODE_SWITCH' && item.type=='boolswitch'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" :disabled="activeStep == 0 && placesSearch == ''" @onCreated="handleComponentCreated" />         
        <Heading v-if="item.name != 'CONF_VAR_FORMMODE_SWITCH' && item.type=='heading'" :item="item" :disabled="activeStep == 0 && placesSearch == ''" @onCreated="handleComponentCreated" />
        <SelectField v-if="item.name != 'CONF_VAR_FORMMODE_SWITCH' && item.type=='select'" :item="item" @onChange="onChange" :storedValue="getFormValue(item)" :disabled="activeStep == 0 && placesSearch == ''" @onCreated="handleComponentCreated" />
      </div>
      <!--<div class="form-item col12 form-item-separated" data-type="boolswitch" v-if="formData.fields && expertModeSwitchIndex >= formData.fields.length">
        <BoolSwitch :item="formModeSwitch" @onChange="handleFormModeChange" :storedValue="{ value: isExpert }" :disabled="activeStep == 0 && placesSearch == ''" />         
      </div> -->
      <div class="form-action col12">
        <button v-if="!displayResult && getBackButtonVisibility()" class="back button--flat " name="configurator-form-back" @click="onClickBack($event)">{{ getContent('CONF_TEXT_BUTTON_BACK', true) }}</button>
        <button type="submit" :class="{ 'is-disabled': submitDisabled }" class="submit button--primary" name="configurator-form-submit" @click="onClickSubmit($event)" ref="domButtonSubmit">{{ getFormButtonText() }}</button>
      </div>
    </form> 
  </div>
</template>

<script>

import { ConfiguratorFormController, GooglePlacesController, RequestController, ContentController } from "@/controller";
import { InputHidden, InputField, SelectField, RadioGroup, CheckGroup, CheckButtonGroup, BoolButton, BoolSwitch, GoogleAutoComplete, Heading } from "./fields";
import { EventBus } from "@/bus/eventbus.js";
import Uniqe from "../../../utils/Uniqe";
import SmoothScrollTo from "@/utils/ScrollTo";

export default {
  name: "ConfiguratorForm",
  components: {
    InputField,
    InputHidden,
    SelectField,
    RadioGroup,
    CheckGroup,
    CheckButtonGroup,
    BoolButton,
    BoolSwitch,
    GoogleAutoComplete,
    Heading
  },
  computed: {
    activeStep() { 
      return this.$store.state.app.activeFormStep;
    },
    displayResult() {
      return this.$store.state.app.displayResult;
    },
    isExpert() {
      console.log("ConfiguratorForm:isExpert", ConfiguratorFormController.getStepExpertMode(this.$store.state.app.activeFormStep));
      return ConfiguratorFormController.getStepExpertMode(this.$store.state.app.activeFormStep) /* == "expert"*/;
    }, 
    language() {
      return this.$store.getters.gLanguage;
    } 
  },
  data() {
    return {
      formData: [],
      formValues: [],
      country: "AT",
      placesSearch: "",
      submitDisabled: true,
      disabled: false,
      //expertModeSwitchIndex: -1,
      formModeSwitch: {
        label: ContentController.getContent("CONF_ADDITIONAL_PARAMS", true),
        name: "formMode"
      },
      //isExpert: ConfiguratorFormController.getStepExpertMode(this.$store.state.app.activeFormStep) /* == "expert"*/,
      expertSwitchShown: 0,
      componentsCreated: [],
    }
  },
  watch: {
    mode() {
      console.log('ConfiguratorForm: mode changed, do refresh. mode: ', this.mode);
        //[WS 2022-10-05] no form reset anymore. because fields aren't no longer dependend on user login only on formMode: expert / basic
      //this.reset();
      ConfiguratorFormController.setStep(this.$store.state.app.activeFormStep);
      ConfiguratorFormController.updateResultOnModeChange();
    },
    async language() {
      const oldFormValues = this.formValues
      await ConfiguratorFormController.getData()
      ConfiguratorFormController.setFormValues(oldFormValues)
      this.formData = ConfiguratorFormController.formFields[this.$store.state.app.activeFormStep];
      ConfiguratorFormController.updateFormFields()
      ConfiguratorFormController.updateResultOnModeChange();
    }
    /* formMode() {
      this.isExpert = this.formMode == 'expert';
      console.log('this formMode changed', ConfiguratorFormController.formMode );
      ConfiguratorFormController.setStep(this.$store.state.app.activeFormStep);
      this.submitDisabled = !ConfiguratorFormController.checkStepCompleted(this.$store.state.app.activeFormStep); 
        //just set new formDada and tell everyone that form has changed
      //this.formData = ConfiguratorFormController.formFields[this.$store.state.app.activeFormStep];
      //this.$emit("onStepChange", this.$store.state.app.activeFormStep);
      //SmoothScrollTo.scroll(this.$refs.domConfiguratorForm);
    } */
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      formMode: {
        type: String,
        default: ""
      }
  },
  created() {
    this.componentCreated = [];
    this.getFormData();
    EventBus.$on("onConfigurationFormSetStep", (noScrollTop) => {
      console.log("ConfiguratorForm:onConfigurationFromSetStep");
      this.updateFormDataOnEdit();
      if (noScrollTop != true) SmoothScrollTo.scroll(this.$refs.domConfiguratorForm);
    });
    EventBus.$on("onConfiguratorFormStepExpertModeChanged", () => {
      console.log("ConfiguratorForm:onConfiguratorFormStepExpertModeChanged:step", this.$store.state.app.activeFormStep);
      ConfiguratorFormController.setStep(this.$store.state.app.activeFormStep, false, true);
      this.submitDisabled = !ConfiguratorFormController.checkStepCompleted(this.$store.state.app.activeFormStep); 
    });
  },
  methods: {
    reset() {
      this.setPlacesValues("", "", "", "", "", "", "");
      ConfiguratorFormController.resetValues();
      ConfiguratorFormController.reset();
      this.getFormData(true);
    },
    resetForm() {
      ConfiguratorFormController.resetValues();
      this.formData = ConfiguratorFormController.formFields[this.$store.state.app.activeFormStep];
      this.formValues = ConfiguratorFormController.getFormValues();
      this.submitDisabled = !ConfiguratorFormController.checkStepCompleted(this.$store.state.app.activeFormStep); 
    },
    getFormButtonText() {
      if (this.$store.state.app.editFormOnResult) {
        return this.getContent("TEXT_REFRESH", true);
      } else {
        return (this.$store.state.app.activeFormStep < ConfiguratorFormController.getFormFieldCount()-1?this.getContent("CONF_TEXT_BUTTON_FURTHER", true):this.getContent("CONF_TEXT_BUTTON_SHOW_RESULTS", true));
      }
    },
    getBackButtonVisibility() {
      return this.$store.state.app.activeFormStep > 0;
    },    
    async getFormData(modeChanged) {
      await ConfiguratorFormController.getFormPart(this.$store.state.app.activeFormStep, modeChanged);
      this.formData = ConfiguratorFormController.formFields[this.$store.state.app.activeFormStep];
      //this.setExpertModeSwitchIndex();
      this.formValues = ConfiguratorFormController.getFormValues();
      if (ConfiguratorFormController.sessionStoredDataAvailable) {
        //this.setPlacesValues(country, lat, lng, address)
        this.placesSearch = ConfiguratorFormController.getSessionStoredValue('CONF_VAR_ADDRESS');
        console.log('ConfiguratorForm -> getFormData got sessionStoredData', this.placesSearch);
        //this.submitDisabled = !ConfiguratorFormController.checkStepCompleted(this.$store.state.app.activeFormStep);     
        //console.log('ConfiguratorForm -> getFormValue country: ' ,this.getFormValue({name: "CONF_VAR_COUNTRY"}));   
        RequestController.setLocationData(
          this.getFormValue({name: "CONF_VAR_COUNTRY"}), 
          this.getFormValue({name: "CONF_VAR_LATITUDE"}),
          this.getFormValue({name: "CONF_VAR_LONGITUDE"}),
          this.getFormValue({name: "CONF_VAR_ADDRESS"})
        );
        ConfiguratorFormController.checkAllSteps();
        console.log('ConfiguratorForm:checkAllSteps:ConfiguratorFormController.allConfigurationDone', ConfiguratorFormController.allConfigurationDone);   
        if (ConfiguratorFormController.allConfigurationDone) this.$store.dispatch("setDisplayResult", true);
      }
    },
    onPlacesChange() {
      GooglePlacesController.fetchPlaces(this.placesSearch);
    },
    onIndividualChange(ev, itemName, value) {
      ConfiguratorFormController.updateFormValue(itemName, value);
    },
    onPlaceChange(country, lat, lng, address, administrative_area_level_1, administrative_area_level_2, administrative_area_level_3) {
      this.setPlacesValues(
        country, 
        lat, 
        lng, 
        address, 
        administrative_area_level_1, 
        administrative_area_level_2, 
        administrative_area_level_3
      );
      ConfiguratorFormController.weatherPrefetch();
    },
    onPlacesReset() {
      this.setPlacesValues("", "", "", "", "", "", "");
      this.resetForm();
    },
    onChange(ev, item, value) {
      ConfiguratorFormController.updateFormValue(item.name, value);
      this.formData = ConfiguratorFormController.formFields[this.$store.state.app.activeFormStep];
      //console.log("ConfiguratorForm:onChange:formData", this.formData);
      //this.setExpertModeSwitchIndex();
      this.submitDisabled = !ConfiguratorFormController.checkStepCompleted(this.$store.state.app.activeFormStep);
    },
    onErrorChange(itemName, hasError) {
      ConfiguratorFormController.updateError(itemName, hasError);
      console.log("ConfiguratorForm:onErrorChange", itemName, hasError, this.formData);
    },
    setPlacesValues(country, lat, lng, address, administrative_area_level_1, administrative_area_level_2, administrative_area_level_3) {

      ConfiguratorFormController.updateFormValue("CONF_VAR_COUNTRY", country);
      ConfiguratorFormController.updateFormValue("CONF_VAR_LATITUDE", lat);
      ConfiguratorFormController.updateFormValue("CONF_VAR_LONGITUDE", lng);
      ConfiguratorFormController.updateFormValue("CONF_VAR_ADDRESS_SEARCHSTR", address);
      ConfiguratorFormController.updateFormValue("CONF_VAR_ADDRESS", address);
      this.placesSearch = address;
        //store values for requests
      RequestController.setLocationData(
        country, 
        lat, 
        lng, 
        address, 
        administrative_area_level_1, 
        administrative_area_level_2, 
        administrative_area_level_3
      );
    },
    getFormValue(item) {
      return ConfiguratorFormController.getFormValue(item);
    },
    onFormSubmit(/*ev*/) {
      //console.log("onSubmit", ev.target);
    },
    onClickSubmit() {
      const formOk = ConfiguratorFormController.checkStepCompleted(this.$store.state.app.activeFormStep);
      if (formOk) {
        if (this.$store.state.app.editFormOnResult) {
            //TODO: not used anymore
          //EventBus.$emit("onUpdateResult");
          ConfiguratorFormController.updateStep();
          this.$store.dispatch("setEditFormOnResult", false);
        } else {
          ConfiguratorFormController.nextStep();
          if (!ConfiguratorFormController.allConfigurationDone) {
            this.formData = ConfiguratorFormController.formFields[this.$store.state.app.activeFormStep];
            //this.setExpertModeSwitchIndex();
              //[WS 2022-12-14] TODO never catched
            //this.$emit("onStepChange", this.$store.state.app.activeFormStep);
            SmoothScrollTo.scroll(this.$refs.domConfiguratorForm);
          } else {
            this.$store.dispatch("setDisplayResult", true);
          }
        }
        this.submitDisabled = !ConfiguratorFormController.checkStepCompleted(this.$store.state.app.activeFormStep);
        if (this.$refs.domButtonSubmit) this.$refs.domButtonSubmit.blur();
      } else {
        console.log("ConfiguratorForm:onClickSubmit:error", formOk);
        EventBus.$emit("onFormError");
        navigator.vibrate([300]);
        if (this.$refs.domButtonSubmit) this.$refs.domButtonSubmit.blur();
      }
    },
    onClickBack() {
      ConfiguratorFormController.prevStep();
      this.formData = ConfiguratorFormController.formFields[this.$store.state.app.activeFormStep];
      //this.setExpertModeSwitchIndex();
        //[WS 2022-12-14] TODO never catched
      //this.$emit("onStepChange", this.$store.state.app.activeFormStep);
      this.submitDisabled = !ConfiguratorFormController.checkStepCompleted(this.$store.state.app.activeFormStep);      
    },
    onMountedConditional(domEl) {
      console.log('onMountedConditional', domEl);
    },
    updateFormDataOnEdit() {
      this.formData = ConfiguratorFormController.formFields[this.$store.state.app.activeFormStep];
      this.submitDisabled = !ConfiguratorFormController.checkStepCompleted(this.$store.state.app.activeFormStep);
      //this.setExpertModeSwitchIndex();
        //[WS 2022-12-14] TODO never catched
      //this.$emit("onStepChange", this.$store.state.app.activeFormStep);      
    },
    getUniqe() {
      Uniqe.getUniqe();
    },
    /* setExpertModeSwitchIndex() {
      this.expertModeSwitchIndex = ConfiguratorFormController.formFields[this.$store.state.app.activeFormStep].expertModeSwitchIndex;
      this.expertSwitchShown = 0;
    }, */
    handleFormModeChange(ev, item, value) {
      //console.log('handleFormModeChange', ev, item, value);
      //ConfiguratorFormController.setFormMode(value ? "expert" : "basic");
      ConfiguratorFormController.setStepExpertMode(this.$store.state.app.activeFormStep, value);
    },
    handleComponentCreated(id) {
      //console.log("ConfiguratorForm:handleComponentCreated:id", this.componentsCreated);
      if (this.componentsCreated.indexOf(id) === -1) this.componentsCreated.push(id);
    },
    componentExists(id, cname) {
      console.log("ConfiguratorForm:componentExists:id", id, cname, this.componentsCreated, this.componentsCreated.indexOf(id) !== -1);
      return this.componentsCreated.indexOf(id) !== -1;
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }      
  },
};
</script>
