<template>
  <Header class="header-main" :class="{ 'service-content': displayGdpr }">
    <div class="logo"> 
      <a href="/" title="iDM Konfigurator" @click.prevent="onLogoClick">
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M70 0H0V70H70V0Z" fill="black"/>
          <path d="M58.1717 52.4771L59.1882 55.1055C61.175 52.7999 62.6074 49.6641 62.6074 45.3756C62.6074 30.4349 54.8912 26.6997 43.5249 26.6997H42.2311H27.5381V61.2847H45.6041C45.6041 61.2847 49.9935 61.008 54.2906 58.8407L55.3995 52.4309H58.1717V52.4771Z" fill="white"/>
          <path d="M65.6569 52.4771H62.7922L60.4358 58.3796L59.1883 55.1516C57.7559 56.8117 56.0002 58.0107 54.2906 58.8868L53.5051 63.4981H56.3698L57.2939 57.1806H57.3401L59.8814 63.4981H61.0365L63.6701 57.1806H63.7163L64.5018 63.4981H67.3665L65.6569 52.4771Z" fill="white"/>
          <path d="M59.1883 55.1514L58.1718 52.5229H55.3533L54.2444 58.9327C56.0464 58.0104 57.7559 56.8115 59.1883 55.1514Z" fill="black"/>
          <path d="M24.9042 26.7456H7.30029V61.3306H24.9042V26.7456Z" fill="#FFF042"/>
          <path d="M40.7063 37.6743H42.4159C46.7129 37.6743 49.2542 39.98 49.2542 44.2224C49.2542 48.6954 46.4357 51.0011 42.2773 51.0011H42.1386H40.7063V37.6743Z" fill="black"/>
          <path d="M25.8283 15.494C25.8283 20.4743 21.4851 24.4861 16.1253 24.4861C10.7656 24.4861 6.42236 20.4743 6.42236 15.494C6.42236 10.5138 10.7656 6.50195 16.1253 6.50195C21.4851 6.50195 25.8283 10.5138 25.8283 15.494Z" fill="#FFF042"/>
        </svg>
      </a>
    </div>
    <ButtonBack :class="{ 'is-hidden': !showDetail }" v-if="!displayGdpr"/>
    <ServiceButtonBack v-if="displayGdpr || $route.meta.displayBackButton" />
    <!-- <div v-if="false" class="dropdown--project" :class="{ 'is-active': mode=='expert' && gDisplayResult }" >
      <Dropdown :items="dataProjects" />
    </div> -->
    <MobileHelpToggle :class="{ 'is-active': gDisplayResult && !showDetail }" />
    <!-- <ExpertNav v-if="mode=='expert' && !gDisplayResult" /> -->
    <div class="wrapper-nav" :class="{ 'is-active': gDisplayResult }">
      <Resultfilter :class="{ 'is-active': gDisplayResult && !showDetail && !displayGdpr }" :displayResult="gDisplayResult" />
      <ResultviewNav :class="{ 'is-active': gDisplayResult && showDetail && !displayGdpr }" />
    </div>
    <div class="login-service" :class="{ 'is-partner': mode=='expert'}">
      <div class="login">
        <button class="button--dense" @click="handleLoginClick" :title="loginButtonTitle">
          <span>{{ loginButtonTitle }}</span>
          <i class="svgicon svgicon--login">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4C7.58172 4 4 7.58172 4 12C4 13.9268 4.6812 15.6946 5.8159 17.0755C6.39655 16.3068 7.11795 15.6498 7.94583 15.1416C8.20533 14.9823 8.47314 14.839 8.74776 14.712C7.8258 13.8456 7.25 12.615 7.25 11.25C7.25 8.62665 9.37665 6.5 12 6.5C14.6234 6.5 16.75 8.62665 16.75 11.25C16.75 12.615 16.1742 13.8456 15.2522 14.712C15.5269 14.839 15.7947 14.9823 16.0542 15.1416C16.882 15.6498 17.6034 16.3068 18.1841 17.0755C19.3188 15.6946 20 13.9268 20 12C20 7.58172 16.4183 4 12 4ZM16.7187 18.4608C16.2674 17.8128 15.6858 17.2622 15.0079 16.8461C14.1409 16.3139 13.1489 16.0217 12.1335 15.9982C12.0891 15.9994 12.0446 16 12 16C11.9554 16 11.9109 15.9994 11.8665 15.9982C10.8511 16.0217 9.85909 16.3139 8.99206 16.8461C8.3142 17.2622 7.73255 17.8128 7.28129 18.4608C8.60415 19.4286 10.2353 20 12 20C13.7646 20 15.3959 19.4286 16.7187 18.4608ZM12.1181 13.9975C13.5821 13.9357 14.75 12.7292 14.75 11.25C14.75 9.73122 13.5188 8.5 12 8.5C10.4812 8.5 9.25 9.73122 9.25 11.25C9.25 12.7292 10.4179 13.9357 11.8819 13.9975C11.9213 13.9969 11.9606 13.9966 12 13.9966C12.0394 13.9966 12.0787 13.9969 12.1181 13.9975ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" fill="white"/>
            </svg>
          </i>
        </button>
      </div>
      <div class="language">
        <button class="button--dense" @click="handleLanguageClick" :title="languageButtonTitle">
          <span>{{ languageButtonTitle }}</span>
          <i class="svgicon svgicon--language">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2c5.523 0 10 4.477 10 10c0 5.523-4.477 10-10 10c-5.523 0-10-4.477-10-10c0-5.523 4.477-10 10-10zm-7.938 9l3.22 0c0.155-2.428 0.873-4.673 2.004-6.528c-2.781 1.003-4.847 3.503-5.224 6.528zm5.224 8.528c-1.131-1.855-1.848-4.099-2.004-6.528l-3.22 0c0.377 3.025 2.443 5.525 5.224 6.528zm2.714-15.393c-1.489 1.708-2.512 4.119-2.713 6.866l5.427 0c-0.202-2.746-1.225-5.157-2.713-6.866zm2.713 8.866l-5.427 0c0.202 2.746 1.225 5.157 2.713 6.866c1.489-1.708 2.512-4.119 2.713-6.866zm2.005-2l3.22 0c-0.377-3.025-2.443-5.525-5.224-6.528c1.131 1.855 1.848 4.099 2.004 6.528zm-2.004 8.528c2.781-1.003 4.847-3.503 5.224-6.528l-3.22 0c-0.155 2.428-0.873 4.673-2.004 6.528z" fill="white"></path>
            </svg>
          </i>
        </button>
      </div>  
      <div class="service-nav-trigger">
        <button class="button--icon" @click="handleServiceNavClicked" title="Service">
          <i class="svgicon svgicon--menusmall">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" fill="#CDDDEC" stroke="#CDDDEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#CDDDEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#CDDDEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </i>
        </button>
        <nav class="service-nav" :class="{ 'is-active': serviceNavActive, 'has-transition': serviceNavTransition, }" :style="serviceNavStyle" @transitionend="onServiceNavTransitionEnd">
          <ul class="service-nav-list" ref="domServiceNavList">
            <li><a href="https://www.idm-energie.at" target="_blank" @click="handleServiceNavClicked">idm-energie.at</a></li>
            <li><a href="https://www.idm-energie.at/impressum/" target="_blank" @click="handleServiceNavClicked">{{ getContent('TEXT_IMPRINT', true) }}</a></li>
            <li><a href="https://www.idm-energie.at/datenschutz/" target="_blank" @click.prevent="handleGdprClicked">{{ getContent('TEXT_DATA_PROTECTION', true) }}</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </Header>
</template>

<script>
import { EventBus } from "@/bus/eventbus.js";
import { mapGetters } from "vuex";
import { LoginController, ConfiguratorFormController, ContentController } from "@/controller";
//import ExpertNav from "./Expertnav.vue";
import ResultviewNav from "./ResultviewNav.vue";
import Resultfilter from "./Resultfilter.vue";
import ButtonBack from "./Back.vue";
import ServiceButtonBack from "./ServiceBack.vue";
//import { Dropdown } from "@/components";
import MobileHelpToggle from "./MobileHelpToggle.vue";

export default {
  name: "HeaderMain",
  components: {
    //ExpertNav,
    Resultfilter,
    //Dropdown,
    ResultviewNav,
    ButtonBack,
    ServiceButtonBack,
    MobileHelpToggle
  },
  computed: {
    ...mapGetters(["gDisplayResult"]),
    mode() {
      return this.$store.state.app.mode;
    },
    showDetail() {
      return this.$store.state.app.showDetail;
    },
    isServicePage() {
      //console.log("Header:isServicePage", this.$route.name == "gdpr");
      return this.$route.name == "gdpr";
    },
    loginButtonTitle() {
      return this.mode=='expert'?LoginController.user.userRealname:this.getContent("CONF_TEXT_LOGIN_BUTTON", true);
    },
    languageButtonTitle() {
      return this.getContent("CONF_LANGUAGE", true);
    }
  },
  data() {
    return {
      serviceNavActive: false,
      serviceNavStyle: "",
      serviceNavTransition: false,
      displayGdpr: false,
    }
  },
  created() {
    EventBus.$on("onShowGdpr", () => {
      this.displayGdpr = true;
    }); 
    EventBus.$on("onHideGdpr", () => {
      this.displayGdpr = false;
    });        
  },
  methods: {
    handleLanguageClick() {
        this.$languageForm.show();
    },
    handleLoginClick() {
      if (this.mode == "expert") {
        LoginController.doLogout();
      } else {
        this.$loginForm.show();
      }
    },
    onLogoClick() {
      this.$dialog({
        title: this.getContent("CONF_LOAD", true), 
        text: this.getContent("CONF_DELETE_EVERYTHING", false),
        modal: true,
        onOk: () => {
          ConfiguratorFormController.removeFormDataFromLocalStorage();
          window.location = "/";
        }
      });
    },
    handleServiceNavClicked() {
      if (this.serviceNavActive) {
        this.collapseServiceNav();
      } else {
        this.expandServiceNav();
      }
    },
    handleGdprClicked() {
      EventBus.$emit("onShowGdpr");
      if (this.serviceNavActive) {
        this.collapseServiceNav();
      } else {
        this.expandServiceNav();
      }
    },
    collapseServiceNav() {
        this.serviceNavStyle = "height: " + this.$refs.domServiceNavList.getBoundingClientRect().height + "px;";
        this.serviceNavTransition = true;
        this.$nextTick(() => {
          this.serviceNavStyle = "height: 0;";
          this.serviceNavActive = false;
        });
    },
    expandServiceNav() {
        this.style = "height: 0;";
        this.serviceNavTransition = true;
        this.serviceNavActive = true;
        this.$nextTick(() => {
          this.serviceNavStyle = "height: " + this.$refs.domServiceNavList.getBoundingClientRect().height + "px;";
        });
    },  
    onServiceNavTransitionEnd() {
      this.serviceNavTransition = false;
    },  
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }
  },
};
</script>
