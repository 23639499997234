<template>
  <div class="page page-system" ref="domSystem" >
    <div class="mobile-system-select">
      <SystemSelect :summaries="summariesFiltered" :selected="selected" @detailChange="onMobileDetailChange" />
    </div>
    <div class="detail__item-list">
      <div class="cardinfo">
        <p class="cardinfo__label">
          <span>{{ getContent('CONF_TEXT_PARTNER_SYSTEM_SELECTED', true) }}</span>
        </p>
      </div>
      <div class="detail__listitemblock"
        v-for="(subItem, idx) in summariesFiltered"
        :key="'listitemBlock' + subItem.uniqeId"
        :class="{ 'is-selected': (idx == 0 && selected == '') || selected == subItem.uniqeId, 'is-notused': subItem.notused }"
      >
        <Listitem 
          :key="subItem.uniqeId"  
          :item="subItem"
          class="detail__listitem"
          :class="{ 'is-selected': (idx == 0 && selected == '') || selected == subItem.uniqeId, 'is-notused': subItem.notused }"
          @onClick="(item) => handleListitemClick(item)"
        />
        <Listitem 
          v-for="(linkedItem, subidx) in subItem.subcards"
          :key="linkedItem.uniqeId"
          :item="linkedItem"
          :parent="subItem"
          :data-linked-index="subidx"
          class="detail__listitem is-sub"
          :class="{ 'is-selected': selected == linkedItem.uniqeId, 'is-notused': (linkedItem.notused || !itemDetailHasProperties(linkedItem)) }"
          @onClick="(item) => handleListitemClick(item, subItem)"  
        />
      </div>
    </div>

    <div class="detail__content" v-if="detailSelected">
      <div class="content">
        <div class="content__image-groups" v-if="detailSelected.picture_group.length">
          <div class="content__image-group-tabheaders">
            <div 
              v-for="(imagegroup, idx) in detailSelected.picture_group"
              :key="'imagegroup_tab_'+idx"
              class="tabheader"
              :class="{ 'is-active': idx == activeImageTab}"
            >
              <button class="button button--flat" @click="handleImageTabClick(idx)">{{ imagegroup.name}}</button>
            </div>
          </div>
          <div class="content__image-group-tabs">
            <div 
              v-for="(imagegroup, idx) in detailSelected.picture_group"
              :key="'imagegroup_'+idx"
              class="content__image-group-tab"
              :class="{ 'is-active': idx == activeImageTab}"
            >
             <Imageslider :item="imagegroup" :active="activeImageTab == idx"/>
            </div>
          </div>
        </div>
        <div class="content__images" v-if="detailSelected.pictures.length">
          <Imageslider :item="detailSelected" />
        </div>
        <div v-if="detailSelected.properties.length" class="content__properties" :class="{ 'is-expanded': propertiesExpanded, 'has-transition': hasPropertiesTransition }" :style="propertiesStyle">
          <div class="property-list" ref="domPropertiesList">
            <div
              v-for="(property, idx) in detailSelected.properties"
              :key="'property_' + idx"
              class="property__listitem"
            >
              <p class="description">{{ property.description }}</p>
              <p class="value">{{ property.value }}</p>
            </div>
          </div>
          <button class="button button--expand" :class="{ 'is-expanded': propertiesExpanded }" @click="handleMorePropertiesClick">
            <span class="label-more">{{getContent('CONF_SHOW_MORE', true)}}</span>
            <span class="label-less">{{getContent('CONF_SHOW_LESS', true)}}</span>
            <i class="svgicon svgicon--chevrondown">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="white"/>
              </svg>
            </i>
          </button>
        </div>
        <div class="detail__sections">
          <Sectionitem 
            v-for="(sec, idx) in detailSelected.sections"
            :key="'section_' + idx"
            :item="sec"
          />
        </div>
        <div class="detail__folder" v-if="selectedItem && selectedItem.flyer">
          <a class="button button--download" :href="selectedItem.flyer" target="_blank">{{ getContent('CONF_PRODUCTFOLDER', true) }}
            <i class="svgicon svgicon--download">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" fill="#CDDDEC"/></svg>
            </i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import Listitem from "../Listitem.vue";
import Sectionitem from "../Sectionitem.vue";
import SystemSelect from "./SystemSelect/SystemSelect.vue";
import Imageslider from "../Imageslider.vue";
import { ImageController, ContentController } from "@/controller";
import { dataButtons } from "@/data";
import SmoothScrollTo from "@/utils/ScrollTo";

export default {
  name: "PageSystem",
  components: {
    Listitem,
    Sectionitem,
    Imageslider,
    SystemSelect
  },
  computed: {
    ...mapGetters(["gSize"]),
    itemSubline() {
      if (this.detailSelected) {
        if ( Array.isArray(this.detailSelected.subline)) {
          return this.detailSelected.subline.join(" ");
        } else {
          return this.detailSelected.subline;
        }
      } else {
        return "";
      }
    },
    dataButtons() {
      return dataButtons;
    },
    summariesFiltered() {
      if (this.page && this.page.summary) {
        return this.page.summary.filter(s => s.notused != true);
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      selected: "",
      detailSelected: null,
      selectedItem: null,
      isProductSelected: false,
      propertiesExpanded: false,
      hasPropertiesTransition: false,
      propertiesStyle: "",
      activeImageTab: 0,
      lastImageTabs: {},
    }
  },
  watch: {
  },
  props: {
      mode: {
        type: String,
        default: ""
      },
      page: {
        type: Object,
        default: null
      },
      item: {
        type: Object,
        default: null
      }
  },
  created() {
    if (this.page  && this.page.summary.length && !this.detailSelected) {
      this.detailSelected = this.page.summary[0].detail;
      this.isProductSelected = true;
    } 
  },
  mounted() {
    if (this.page && this.page.summary.length && !this.selected) {
      this.selected = this.page.summary[0].uniqeId;
      this.selectedItem = this.page.summary[0];
    }
  },
  methods: {
    handleClick(ev) {
      this.$emit("onClick", ev);
    },
    getPropertiesCollapsedHeight() {
      return 300; //(this.gSize.screen.width<1024?378:280);
    },
    handleListitemClick(item, parent) {
      if (parent) item = parent;
      if (!item.notused && this.itemDetailHasProperties(item)) {
        this.activateItem(item);
      }
      //window.scrollTo({top: 0, behavior: 'smooth'});
    },
    onMobileDetailChange(item) {
      if (this.itemDetailHasProperties(item)) {
        this.activateItem(item);
      }
    },
    activateItem(item) {
      this.lastImageTabs[this.selected] = this.activeImageTab;
      this.selected = item.uniqeId;
      this.selectedItem = item;
      this.isProductSelected = this.page.summary.length && this.page.summary[0].uniqeId == item.uniqeId;
      this.detailSelected = item.detail;
      if (this.lastImageTabs[item.uniqeId]) {
        this.activeImageTab = this.lastImageTabs[item.uniqeId];
      } else {
        this.activeImageTab = 0;
      }
      SmoothScrollTo.scroll(this.$refs.domSystem, 110);
    },
    getImageSource(image) {
      return ImageController.getItemImageSrc(image);
    },
    handleMorePropertiesClick() {
      if (this.propertiesExpanded) {
        this.collapseProperties();
      } else {
        this.expandProperties();
      }
    },
    handleImageTabClick(idx) {
      console.log("System:handleImageTabClick:this.detailSelected", this.detailSelected, this.selected);
      this.lastImageTabs[this.selected] = idx;
      this.activeImageTab = idx;
    },
    collapseProperties() {
      this.propertiesStyle = "height: " + this.$refs.domPropertiesList.getBoundingClientRect().height + "px;";
      this.hasPropertiesTransition = true;
      this.$nextTick(() => {
        this.propertiesStyle = "height: " + this.getPropertiesCollapsedHeight() + "px;";
        this.propertiesExpanded = false;
      });
    },
    expandProperties() {
      this.style = "height: " + this.getPropertiesCollapsedHeight() + "px;";
      this.hasPropertiesTransition = true;
      this.propertiesExpanded = true;
      this.$nextTick(() => {
        this.propertiesStyle = "height: " + this.$refs.domPropertiesList.getBoundingClientRect().height + "px;";
      });
    },
    onPropertiesTransitionend() {
      this.hasPropertiesTransition = false;
      //this.style = "";
    },    
    itemDetailHasProperties(item) {
      return Object.keys(item.detail).length > 0;
    },
    getContent(content, clean) {
      return ContentController.getContent(content, clean);
    }     
  },
};
</script>