const getters = {
    gLanguage: state => state.app.language,
    gSize: state => state.app.size,
    gDevice: state => state.app.device,
    gPermission_routers: state => state.permission.routers,
    gAddRouters: state => state.permission.addRouters,
    gUser: state => state.user,
    gActiveFormStep: state => state.app.activeFormStep,
    gDisplayResult: state => state.app.displayResult,
    gContentData: state => state.app.contentData,
  };
  
  export default getters;